import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteApi, postApi } from "../../api/methods";
import { isLoading } from "../../store/features/common/commonSlice";
import { addPostForPreview } from "../../store/features/post/postSlice";
import { useLocation, useNavigate } from "react-router-dom";
import "./HistoryPostCard.css";
import { IMAGE_URL, IMAGE_URL_BACKEND, apibase } from "../../api/API";
import {
  addEvergreenData,
  fetchUserEverGreen,
} from "../../store/features/evergreen/evergreenSlice";
import {
  addBookmarkData,
  fetchUserBookmark,
} from "../../store/features/bookmark/bookmarkSlice";
import {
  addPreviewPost,
  togglePreview,
} from "../../store/features/previewPost/previewPostSlice";
import convertCodeToText from "../../utility/convertCodeToText";
import axios from "axios";
import { fetchUserOTGById } from "../../store/features/otg/otgSlice";
import { addDiscoverData } from "../../store/features/discover/discoverSlice";
import { v4 as uuidv4 } from "uuid";
import { POST_GENERATION_TYPES } from "../../store/features/post/initialState";
import { setPostGenerationType } from "../../store/features/post/postSlice";
// import PdfCarousel from "./PdfCarousel";
const PostCard = (props) => {
  const [discoverImage, setDiscoverImage] = useState(
    props.data?.discoverData?.profileImage
      ? props.data?.discoverData?.profileImage
      : props.data?.profileImage
      ? props.data.profileImage
      : `${IMAGE_URL}/images/Icons/avatar.jpg`
  );

  const location = useLocation();
  let pathName = location.pathname;
  // console.log({props});
  const user = useSelector((state) => state.user);
  const previewPost = useSelector((state) => state.previewPost);
  const { isDark } = useSelector((state) => state.common);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const post = useSelector((state) => state.post);
  const [modalData, setModalData] = useState(null);
  const [url, setUrl] = useState("");
  const userInfo = user?.userData;
  const [imageError, setImageError] = useState(false);
  const [evergreenStatus, setEvergreenStatus] = useState(
    props.data?.Evergreen
      ? true
      : pathName === "/evergreen" || pathName === "/evergreen/"
      ? true
      : false
  );
  const [bookmarkStatus, setBookmarkStatus] = useState(
    props.data?.Bookmark
      ? true
      : pathName === "/bookmarks" || pathName === "/bookmarks/"
      ? true
      : false
  );

  // Function to handle evergreen status toggle
  // here create a new evergreen post and evergreen empty symbol to fill with color symbol
  useEffect(() => {
    console.log("props:", props);
    if (props.postype === "ARTICLE") {
      console.log("Setting URL:", props.data.image);
      setUrl(props.image);
    }
  }, [props.postType, props.image]);

  useEffect(() => {
    if (url !== "") {
      fetchMetaData();
    }
    console.log("This is useEffect, url:", url);
  }, [url]);

  const fetchMetaData = async () => {
    try {
      const response = await axios.post(`${apibase}/api/get-meta-data`, {
        url: url,
      });
      setModalData(response.data);
      console.log("This is fetchMetaData, response.data:", response.data[0]);
      console.log(url);
    } catch (error) {
      console.error("Error fetching metadata:", error);
      setModalData({
        data: { ogTitle: url, ogImage: [{ url: "", type: "png" }] },
      });
    }
  };

  const onEvergreenHnadler = async () => {
    // Toggle the evergreen status
    setEvergreenStatus(!evergreenStatus);

    // Check if the post is already marked as evergreen
    if (evergreenStatus) {
      // If marked as evergreen, delete the evergreen mark
      onDeleteHnadler({ evergreenMark: true });
      dispatch(
        fetchUserEverGreen({ userId: user?.userData?.linkedinId, page: 1 })
      );
    } else {
      try {
        dispatch(isLoading(true));

        // Create a new evergreen post
        const postBody = {
          userId: user.userData.linkedinId,
          postId: props.data.userName
            ? `discover_${uuidv4()}`
            : props.data.postId,
          postContent: props.data.postContent,
          connectionType: props.data.connectionType || "PUBLIC",
          postType: props.data.postType || "NONE",
          mediaDis: props.data.mediaDis || "",
          mediaTitle: props.data.mediaTitle || "",
          media: props.data.media || "",
          originalUrl: props.data.originalUrl || "",
        };

        // Make a request to create the evergreen post
        const response = await postApi("evergreen/create", postBody);
        toast.success("Saved as evergreen!");
        setEvergreenStatus(true);
        dispatch(
          fetchUserEverGreen({ userId: user?.userData?.linkedinId, page: 1 })
        );
        dispatch(isLoading(false));
      } catch (error) {
        dispatch(isLoading(false));
        console.warn(error);
        if (error.response.status === 409) {
          const message = error.response.data.message;
          toast.error(message);
          setEvergreenStatus(true);
        } else {
          const message = "Something went wrong!";
          toast.error(message);
        }
      }
    }
  };

  // Function to handle bookmark status toggle
  // here create a new bookmark post and bookmark empty symbol to fill with color symbol
  const onBookmarkHnadler = async () => {
    // Toggle the bookmark status
    setBookmarkStatus(!bookmarkStatus);

    // Check if the post is already bookmarked
    if (bookmarkStatus) {
      // If bookmarked, delete the bookmark
      onDeleteHnadler({ bookmarkMark: true });
      dispatch(
        fetchUserBookmark({ userId: user?.userData?.linkedinId, page: 1 })
      );
    } else {
      try {
        dispatch(isLoading(true));

        // Create a new bookmark
        const postBody = {
          userId: user.userData.linkedinId,
          postId: props.data.userName
            ? `discover_${uuidv4()}`
            : props.data.postId,
          postContent: props.data.postContent,
          connectionType: props.data.connectionType || "PUBLIC",
          postType: props.data.postType || "NONE",
          mediaDis: props.data.mediaDis || "",
          mediaTitle: props.data.mediaTitle || "",
          media: props.data.media || "",
          originalUrl: props.data.originalUrl || "",
          discoverData: props.data.userName ? props.data : null,
        };

        // Make a request to create the bookmark
        const response = await postApi(
          "book-marks/bookmark-post-create",
          postBody
        );
        toast.success("Saved in Bookmark!");
        setBookmarkStatus(true);
        dispatch(
          fetchUserBookmark({ userId: user?.userData?.linkedinId, page: 1 })
        );

        if (postBody.postId.split("_")[0] === "discover") {
          // dispatch(addDiscoverData({ data:[], isNew:true }))
        }
        dispatch(isLoading(false));
      } catch (error) {
        dispatch(isLoading(false));
        console.warn(error);
        if (error?.response?.status === 409) {
          const message = error.response.data.message;
          toast.error(message);
          setBookmarkStatus(true);
        } else {
          const message = "Something went wrong!";
          toast.error(message);
        }
      }
    }
  };

  const onComposeHandler = () => {
    console.log("props.data.postId");
    console.log(props.data.postId);
    console.log("props.data.postId");
    if (!props.data.postId.startsWith("otg_")) {
      console.log("discover post");
      dispatch(setPostGenerationType(POST_GENERATION_TYPES.DISCOVER));
    } else {
      dispatch(setPostGenerationType(POST_GENERATION_TYPES.ON_THE_GO));
    }
    dispatch(addPostForPreview(props.data));
    // deleteApi(`schedule/delete-schedule-post/${props.data._id}`);
    navigate("/ai-content");
  };

  // here if user click evergreen hightlight symbol then evergreen post will be deleted
  // here if user click bookmark hightlight symbol then bookmark post will be deleted
  const onDeleteHnadler = async (mark = {}) => {
    // console.log(props.data, props.isEvergreen, props.isBookmark );
    // if(props?.data?.postId?.split('_')[0] === 'otg' || props?.data?.postId?.split('_')[0] === 'discover'){
    //     toast.info('Please unbookmark from Bookmarks section!')
    //     if( bookmarkStatus ){
    //         setBookmarkStatus(true)
    //     }
    //     if( evergreenStatus ){
    //         setEvergreenStatus(true)
    //     }
    //     return
    // }

    try {
      if (
        bookmarkStatus &&
        (pathName === "/bookmarks" || pathName === "/bookmarks/")
      ) {
        const confirmDelete = confirm("Are you sure?");

        if (bookmarkStatus && confirmDelete) {
          dispatch(isLoading(true));
          const response = await deleteApi("book-marks/bookmark-post-delete", {
            id: props.data.postId,
          });
          toast.success("Post deleted in Bookmark.");
          props?.setData(response.data);
          setBookmarkStatus(true);
          dispatch(isLoading(false));
          if (props.data.postId.split("_")[0] === "otg") {
            dispatch(fetchUserOTGById({ userId: user.userData?.linkedinId }));
          }
          if (props.data.postId.split("_")[0] === "discover") {
            // dispatch(addDiscoverData({ data:[], isNew:true }))
          }
        }
      } else {
        if (bookmarkStatus && mark.bookmarkMark) {
          toast.info("Please unbookmark from Bookmarks section");
          setBookmarkStatus(true);
        }
      }

      if (
        evergreenStatus &&
        (pathName === "/evergreen" || pathName === "/evergreen/")
      ) {
        const confirmDelete = confirm("Are you sure?");

        if (evergreenStatus && confirmDelete) {
          dispatch(isLoading(true));
          const response = await deleteApi("evergreen/evergreen-post-delete", {
            id: props.data.postId,
          });
          toast.success("Post deleted from Evergreen!");
          props?.setData(response.data);
          setEvergreenStatus(true);
          dispatch(isLoading(false));
          if (props.data.postId.split("_")[0] === "otg") {
            dispatch(fetchUserOTGById({ userId: user.userData?.linkedinId }));
          }
        }
      } else {
        if (evergreenStatus && mark.evergreenMark) {
          toast.info("Please remove from Evergreens section");
          setEvergreenStatus(true);
        }
      }
    } catch (error) {
      toast.info("Something went wrong!");
      dispatch(isLoading(false));
      console.warn(error);
    }
  };

  // preview post in global panel
  const previewPostCardHandler = () => {
    // console.log(props.data);
    dispatch(addPreviewPost(props.data));
    dispatch(togglePreview(true));
  };

  // view profile of discover posts only
  const viewProfileHandler = () => {
    let url;
    if (props.data.platform) {
      url = "http://www.twitter.com";
    }

    const urlLink = `${url}/${props.data.userName}`;

    if (props.data?.userName) {
      window.open(urlLink, "_blank").focus();
    }
  };

  useEffect(() => {}, [bookmarkStatus, evergreenStatus]);
  // console.log(props.data);

  // manupulate date and time for post
  let date;
  let time;

  if (props?.data?.date_time) {
    const currentDate = new Date(`${props?.data?.date_time}.000Z`);
    date = currentDate.toLocaleDateString();
    time = currentDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  } else if (props?.data?.createdAt) {
    const currentDate = new Date(`${props?.data?.createdAt}`);
    date = currentDate.toLocaleDateString();
    time = currentDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  // date format manupulate
  if (date) {
    if (date?.split("/")[0] === date?.split("/")[0]) {
      const initialDate = date?.split("/");
      // console.log({initialDate});
      date = [initialDate[1], initialDate[0], initialDate[2]]?.join("-");
    } else {
      const initialDate = date?.split("/");
      // console.log({initialDate});
      date = [initialDate[0], initialDate[1], initialDate[2]]?.join("-");
    }
  }

  useEffect(() => {}, [bookmarkStatus, evergreenStatus]);

  //   useEffect(()=>{

  //     if(props.data?.profileImage){
  //         axios.get(props.data?.profileImage)
  //             .then(response=>{
  //                 if (response.status === 200) {
  //                     setDiscoverImage(props.data?.profileImage)
  //                   }
  //             })
  //             .catch(error=>{

  //             })
  //       }

  //   },[props.data?.profileImage])
  // console.log(props.data.originalUrl);

  // this is the function to open the linkedin post in a new tab
  const openLinkedInPost = () => {
    if (props.data?.postUrn) {
      // this is the base url of the linkedin post
      const baseUrl = "https://www.linkedin.com/feed/update/";
      // this is the full url of the linkedin post
      const fullUrl = baseUrl + props.data.postUrn;
      // this will open the post in new tab
      window.open(fullUrl, "_blank");
    }
  };

  return (
    <div>
      <div className="w-full">
        <div className="flex max-sm:flex-col justify-between w-full">
          <div className="w-full">
            {/* <div className="w-full flex justify-between items-center mb-8">
              <h3 className="text-[15px] font-semibold text-gray-600">
                Preview
              </h3>
            </div> */}

            <div className="bg-white rounded-xl shadow-sm border border-gray-200">
              {/* Header */}
              <div className="p-4 pb-2">
                <div className="flex items-start justify-between">
                  <div className="flex gap-3">
                    <img
                      src={
                        user?.userPhoto ||
                        `${IMAGE_URL}/images/Icons/avatar.jpg`
                      }
                      className="w-12 h-12 rounded-full border object-cover"
                      alt="Profile"
                    />

                    <div className="flex flex-col">
                      <span className="font-semibold text-[14px] text-black hover:text-blue-600 cursor-pointer">
                        {user?.userData?.userName?.length > 30
                          ? `${user?.userData?.userName.slice(0, 30)}...`
                          : user?.userData?.userName}
                      </span>

                      <span className="text-[10px] text-black">
                        {user?.userData?.localizedHeadline?.length > 30
                          ? `${user?.userData?.localizedHeadline.slice(
                              0,
                              30
                            )}...`
                          : user?.userData?.localizedHeadline}
                      </span>

                      <span className="text-[10px] text-black flex items-center gap-1">
                        {date ? `${date} ${time}` : "Now"} •{" "}
                        <i className="fa-solid fa-earth-americas text-[10px]"></i>
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    {
                      <span
                        className="gradient-button-new p-1 px-2 text-xs rounded-md text-white"
                        style={{}}
                      >
                        {/* this is the code to get he post type in blue bg */}
                        {props?.data?.platform ||
                          (props?.postype === "CAROUSEL"
                            ? "CAROUSEL"
                            : props?.data?.postId?.split("_")[0] === "otg"
                            ? "OTG"
                            : props?.data?.postId?.split("_")[0] === "schedule"
                            ? "SCHEDULED"
                            : props?.data?.postId?.split("_")[0] === "ai"
                            ? "AI POST"
                            : props?.data?.postId?.split("_")[0] === "discover"
                            ? "DISCOVER"
                            : props?.data?.postId?.split("_")[0] === "custom"
                            ? "CUSTOM"
                            : props?.data?.postId?.split("_")[0]
                            ? props?.data?.postId?.split("_")[0].toUpperCase()
                            : props?.postype?.toUpperCase() || "UNKNOWN")}
                      </span>
                    }
                    {props?.postURL && (
                      <a
                        className="discover-url"
                        href={props?.postURL}
                        target="_blank"
                      >
                        <i
                          className="fa-solid fa-link"
                          title="URL"
                          style={{ color: isDark ? "#121212" : "#FFFFFF" }}
                        ></i>
                      </a>
                    )}

                    {props.isDelete && (
                      <i
                        className="fa-regular fa-trash-can cursor-pointer"
                        onClick={onDeleteHnadler}
                        title="Delete"
                      ></i>
                    )}
                    {props.isEvergreen &&
                      (evergreenStatus ? (
                        <i
                          className="fa-solid fa-star cursor-pointer"
                          style={{ color: isDark ? "#121212" : "#000000" }}
                          onClick={onEvergreenHnadler}
                          title="Evergreen"
                        ></i>
                      ) : (
                        <i
                          className="fa-regular fa-star cursor-pointer"
                          onClick={onEvergreenHnadler}
                          style={{ color: isDark ? "#121212" : "#000000" }}
                          title="Evergreen"
                        ></i>
                      ))}
                    {props.isBookmark &&
                      (bookmarkStatus ? (
                        <i
                          className="fa-solid fa-bookmark cursor-pointer"
                          style={{ color: isDark ? "#121212" : "#000000" }}
                          onClick={onBookmarkHnadler}
                          title="Bookmark"
                        ></i>
                      ) : (
                        <i
                          className="fa-regular fa-bookmark cursor-pointer"
                          style={{ color: isDark ? "#121212" : "#000000" }}
                          onClick={onBookmarkHnadler}
                          title="Bookmark"
                        ></i>
                      ))}
                    <i
                      className="fa-regular fa-pen-to-square cursor-pointer"
                      title="Repurpose"
                      style={{ color: isDark ? "#121212" : "#000000" }}
                      onClick={onComposeHandler}
                    ></i>

                    <i
                      className="fa-brands fa-linkedin text-[#0a66c2] text-xl cursor-pointer"
                      onClick={openLinkedInPost}
                      title="Open in LinkedIn"
                    ></i>
                  </div>
                </div>
              </div>

              {/* Content */}
              <div className="px-4 pt-1">
                {(() => {
                  const content = props.des || "";

                  const parts = content.split(/(#[^\s#]+)/g);

                  const mainContent = parts
                    .filter((part) => !part.startsWith("#"))
                    .join(" ")
                    .trim();

                  const hashtags = parts
                    .filter((part) => part.startsWith("#"))
                    .join(" ");

                  return (
                    <div className="text-[13px] text-black whitespace-pre-wrap">
                      {mainContent}

                      {hashtags && (
                        <>
                          {"\n\n"}

                          <span className="text-black font-bold">
                            {hashtags}
                          </span>
                        </>
                      )}
                    </div>
                  );
                })()}
              </div>

              {/* Media */}
              {/* {renderMediaGallery()} */}

              {/* Article Preview */}
              {post.originalUrl &&
                post.postType === "ARTICLE" &&
                modalData?.data && (
                  <div className="mx-4 mt-4 border border-gray-200 rounded-lg overflow-hidden hover:bg-gray-50 transition-colors cursor-pointer">
                    <a
                      href={post.originalUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block"
                    >
                      {modalData.data.ogImage?.[0]?.url && (
                        <img
                          src={modalData.data.ogImage[0].url}
                          className="w-full h-52 object-cover"
                          alt="Article preview"
                        />
                      )}

                      <div className="p-3">
                        <h4 className="font-semibold text-[14px] text-gray-900">
                          {modalData.data.ogTitle}
                        </h4>

                        <p className="text-[12px] text-gray-600 mt-1 line-clamp-2">
                          {modalData.data.ogDescription}
                        </p>
                      </div>
                    </a>
                  </div>
                )}

              {/* PDF/Carousel Preview */}
              {(props.postype === "carousel" ||
                props.postype === "CAROUSEL") && (
                <div className="mx-4 mt-4 mb-4">
                  <div className="relative w-full bg-gray-100 rounded-lg overflow-hidden">
                    <div className="w-full">
                      {props.data?.media?.[0] ? (
                        <div className="w-full">
                          <iframe
                            src={
                              props.data.media[0] +
                              "#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&view=Fit"
                            }
                            className="w-full h-[450px] rounded-lg"
                            style={{
                              border: "none",
                              background: "white",
                            }}
                          />
                        </div>
                      ) : (
                        <div className="flex items-center justify-center h-[450px]">
                          <div className="text-center">
                            <i className="far fa-file-pdf text-gray-400 text-4xl mb-2"></i>
                            <p className="text-gray-500 text-sm">
                              No PDF available
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {/* Video */}
              {post.mediaTitle && post.postType === "VIDEO" && (
                <div className="mx-4 mt-4 relative">
                  <video
                    src={`${IMAGE_URL_BACKEND}/uploads/${post.mediaTitle}`}
                    className="w-full rounded-lg"
                    controls
                  />

                  {!props.isDeleteBtnHide && (
                    <button
                      onClick={() => deleteMedia()}
                      className="absolute top-2 right-2 p-2 bg-white/80 rounded-full hover:bg-white transition-colors group"
                      title="Delete"
                    >
                      <i className="fa-regular fa-trash-can text-gray-700 group-hover:text-gray-900" />
                    </button>
                  )}
                </div>
              )}

              {/* This is the code to show the post pdf of carousel */}
              <div>
                {/* {props.image ? <img src={`${IMAGE_URL_BACKEND}/uploads/${props.image}`} className='w-100' /> : ''} */}
                {Array.isArray(props.data.mediaDis) &&
                  props.postype === "IMAGE" && (
                    <div className="bg-white border-r-2">
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                          marginBottom: "30px",
                        }}
                      >
                        {props.data.mediaDis.length === 1 ? (
                          // Single image case
                          <div style={{ width: "100%", position: "relative" }}>
                            <img
                              src={`${IMAGE_URL_BACKEND}/uploads/${props.data.mediaDis[0]}`}
                              style={{
                                width: "100%",
                                height: "auto",
                                objectFit: "contain",
                                borderRadius: "10px",
                              }}
                              alt="Preview Image 1"
                            />
                          </div>
                        ) : props.data.mediaDis.length === 2 ? (
                          // Two images case
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              gap: "10px",
                            }}
                          >
                            {props.data.mediaDis.map((url, index) => (
                              <div
                                key={index}
                                style={{ flex: 1, position: "relative" }}
                              >
                                <img
                                  src={`${IMAGE_URL_BACKEND}/uploads/${url}`}
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    objectFit: "cover",
                                    borderRadius: "10px",
                                  }}
                                  alt={`Preview Image ${index + 1}`}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          // Three or more images case
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              gap: "10px",
                            }}
                          >
                            <div style={{ flex: 2, position: "relative" }}>
                              <img
                                src={`${IMAGE_URL_BACKEND}/uploads/${props.data.mediaDis[0]}`}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: "10px",
                                }}
                                alt="Preview Image 1"
                              />
                            </div>
                            <div
                              style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                              }}
                            >
                              {props.data.mediaDis
                                .slice(1, 4)
                                .map((url, index) => (
                                  <div
                                    key={index + 1}
                                    style={{ flex: 1, position: "relative" }}
                                  >
                                    <img
                                      src={`${IMAGE_URL_BACKEND}/uploads/${url}`}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        borderRadius: "10px",
                                      }}
                                      alt={`Preview Image ${index + 2}`}
                                    />
                                    {index === 2 &&
                                      props.data.mediaDis.length > 4 && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            backgroundColor: "rgba(0,0,0,0.5)",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            color: "white",
                                            fontSize: "24px",
                                            fontWeight: "bold",
                                            borderRadius: "10px",
                                          }}
                                        >
                                          +{props.data.mediaDis.length - 4}
                                        </div>
                                      )}
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
              </div>

              {/* <div className="carousel-preview">
                <div className="carousel-header">
                  <i className="far fa-file-pdf"></i>
                  <div className="pdf-title">
                    <h4>
                      {props.data?.mediaTitle} • {props.data?.mediaDis.length}{" "}
                      pages
                    </h4>
                  </div>
                </div>

                <div className="carousel-content" ref={previewRef}>
                  {props.data?.media?.[0] ? (
                    <div className="pdf-preview-container">
                      <img
                        src={`${IMAGE_URL_BACKEND}/uploads/${props.data?.media?.[0]}`}
                        alt="PDF Preview"
                        className="w-full h-auto rounded-lg shadow-md"
                      />
                    </div>
                  ) : (
                    <div className="capture-container">
                      <div className="slide-content">
                        <h3>{props.data?.mediaTitle}</h3>
                        <p>{props.data?.mediaDis[currentSlide]?.subtitle}</p>
                        <p>{props.data?.mediaDis[currentSlide]?.desc}</p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="carousel-footer">
                  <div className="page-counter">
                    {currentSlide + 1} / {props.data?.mediaDis.length}
                  </div>
                  <div className="carousel-controls">
                    <button
                      className="nav-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setCurrentSlide((prev) => (prev > 0 ? prev - 1 : prev));
                      }}
                      disabled={currentSlide === 0}
                    >
                      <i className="fas fa-chevron-left"></i>
                    </button>
                    <button
                      className="nav-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setCurrentSlide((prev) =>
                          prev < props.data?.mediaDis.length - 1
                            ? prev + 1
                            : prev
                        );
                      }}
                      disabled={
                        currentSlide === props.data?.mediaDis.length - 1
                      }
                    >
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  </div>
                </div>
              </div> */}

              {/* Post Actions: like, comment, repost, send */}
              <div className="mt-4 px-4 pb-2">
                <hr className="border-gray-200" />

                <div className="grid grid-cols-4 -mx-1 mt-1">
                  <button className="flex items-center justify-center gap-2 py-3 rounded-lg transition-colors group">
                    <i className="fa-regular fa-thumbs-up text-black"></i>

                    <span className="text-[13px] font-medium text-black">
                      Like
                    </span>
                  </button>

                  <button className="flex items-center justify-center gap-2 py-3 rounded-lg transition-colors group">
                    <i className="fa-regular fa-comment text-black"></i>

                    <span className="text-[13px] font-medium text-black">
                      Comment
                    </span>
                  </button>

                  <button className="flex items-center justify-center gap-2 py-3 rounded-lg transition-colors group">
                    <i className="fa-solid fa-retweet text-black"></i>

                    <span className="text-[13px] font-medium text-black">
                      Repost
                    </span>
                  </button>

                  <button className="flex items-center justify-center gap-2 py-3 rounded-lg transition-colors group">
                    <i className="fa-regular fa-paper-plane text-black"></i>

                    <span className="text-[13px] font-medium text-black">
                      Send
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostCard;
